<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header inf="Los documentos descargados, estarán disponibles en el centro de descarga, durante 30 días." :breadcrumbs="breadcrumbs" :hiddenTab="true" :scroll="scroll" :dense="true">
      <template v-slot:main>
        <v-query-builder :query-map="filters" model="download-center" />
      </template>
    </mini-header>
    <!-- end header -->

    <v-col cols="12" class="px-0 pt-5 mt-20">
      <skeleton-list v-if="loading" />
      <template v-else>
        <template v-if="tasksList.length" class="text-center py-5">
          <v-data-table
            :class="{'fixed-table' : $store.state.base.isExpandNavigationDrawer}"
            :headers="headers"
            :items="tasksList"
            :items-per-page="pagination"
            mobile-breakpoint
            hide-default-header
            disable-sort
            hide-default-footer
            fixed-header
            :height="heightTable"
          >
            <template v-slot:header="{ props: { headers } }">
              <VTableHeaders :headers="headers" @sort="setSort" />
                <v-divider style="position: absolute; z-index: 2; margin-top: -6px; min-width: 100%; margin-left: -5px" />
              </template>
            <template v-slot:[`item.status`]="{item}">
              <v-chip :color="statusList[item.status].color" small label>
                <span >{{ statusList[item.status].detail}}</span>
                <v-icon right>{{statusList[item.status].icon}}</v-icon>
              </v-chip>
            </template>
            <template v-slot:[`item.quantity`]="{item}">
              <span class="body-1 mr-n1">{{item.quantity | number}}</span>
            </template>
            <template v-slot:[`item.type_task`]="{item}">
              <span class="text-right">{{typeTask[item.type_task]}}</span>
            </template>
            <template v-slot:[`item.type_file`]="{item}">
              <span class="text-right">{{item.type_file.toUpperCase()}}</span>
            </template>
            <template v-slot:[`item.created`]="{item}">
              <span class="text-right">{{item.created | date}}</span>
            </template>
            <template v-slot:[`item.date_expiration_link`]="{item}">
              <span class="text-right" v-if="item.date_expiration_link">{{item.date_expiration_link | date}}</span>
              <span v-else>-</span>
            </template>
            <template v-slot:[`item.actions`]="{item}">
              <v-btn v-if="item.status === 'finalized'" :href="item.storage_link" download :ripple="false" color="blue-500" text>Descargar</v-btn>
            </template>
          </v-data-table>
          <VMainPagination :count="tasksList?.length" />
        </template>
        <v-empty-state v-else type="documents" id="documentos" title="Aún no hay descargas disponibles" :isFree="true">
          <!-- <template v-slot:actions>
            <v-btn @click="$emit('activeBtnFilter')" color="blue-500" :ripple="false" :width="153"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn>
            <v-btn class="ml-2" @click="setSort('-date')" outlined :ripple="false" width="153"><v-icon class="mr-2">mdi-text-search-variant</v-icon>Últimos registros</v-btn>
          </template> -->
        </v-empty-state>
      </template>
    </v-col>
  </v-row>
</template>
<script>
 import { mapState } from 'vuex'
import MiniHeader from '@/components/commons/MiniHeader'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import VEmptyState from '@/components/commons/VEmptyState'
import ListViewMixin from '@/mixins/ListViewMixin'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import VMainPagination from '@/components/commons/VMainPagination'

export default {
  components: {
    VQueryBuilder,
    VTableHeaders,
    VEmptyState,
    SkeletonList,
    VMainPagination,
    MiniHeader
  },
  mixins: [
    GenericViewMixin,
    ListViewMixin
  ],
  data: () => ({
    scroll: 0,
    filters: [
      {
        type: 'select',
        id: 'type_task',
        label: 'Tipo de descarga',
        value: null,
        // valueType: 'number',
        choices: [
          {
            label: 'Documentos emitidos',
            value: 'documents_issued'
          },
          {
            label: 'Documentos recibidos',
            value: 'documents_received'
          },
          {
            label: 'Documentos distribuidos - reporte',
            value: 'documents_distributed'
          }
        ],
        category: 'd',
        countries: ['PE', 'CL']
      },
      {
        type: 'select',
        id: 'type_file',
        label: 'Formato',
        value: null,
        // valueType: 'number',
        choices: [
          {
            label: 'XML',
            value: 'xml'
          },
          // {
          //   label: 'PDF',
          //   value: 'pdf'
          // },
          {
            label: 'XLSX',
            value: 'xlsx'
          },
          {
            label: 'CSV',
            value: 'csv'
          }
        ],
        category: 'd',
        countries: ['PE', 'CL']
      },
      {
        type: 'select',
        id: 'status',
        label: 'Estado',
        value: null,
        // valueType: 'number',
        choices: [
          {
            label: 'Descarga en proceso',
            value: 'pending'
          },
          {
            label: 'Descarga disponible',
            value: 'finalized'
          },
          {
            label: 'Error de descarga',
            value: 'failed'
          },
          {
            label: 'Descarga caducada',
            value: 'expired'
          }
        ],
        category: 'd',
        countries: ['PE', 'CL']
      },
      {
        type: 'numeric',
        id: 'quantity',
        label: 'Registros',
        value: null,
        value2: null,
        category: 'c',
        operator: null,
        operators: ['es igual', 'es menor a', 'es mayor a', 'rango'],
        countries: ['PE', 'CL']
      },
      {
        type: 'date',
        id: 'created',
        value: null,
        value2: null,
        category: 'b',
        label: 'Fecha de solicitud',
        operators: ['es igual', 'es menor a', 'es mayor a', 'rango']
      },
      {
        type: 'date',
        id: 'date_expiration_link',
        value: null,
        value2: null,
        category: 'b',
        label: 'Disponible hasta',
        operators: ['es igual', 'es menor a', 'es mayor a', 'rango']
      }
    ],
    headers: [
      { text: 'Tipo de descarga', value: 'type_task', class: 'font-weight-bold', sortable: true },
      { text: 'Formato', value: 'type_file' },
      { text: 'Estado', value: 'status', sortable: true },
      { text: 'Registros', value: 'quantity', align: 'end', sortable: true },
      { text: 'Fecha de solicitud', value: 'created', align: 'end', sortable: true },
      { text: 'Disponible hasta', value: 'date_expiration_link', align: 'end', sortable: true },
      { text: '', value: 'actions', align: 'end'}
    ],
    loading: false,
    statusList: {
      pending: {
        detail: 'Descarga en proceso',
        color: 'yellow-100',
        icon: 'mdi-clock '
      },
      finalized: {
        detail: 'Descarga disponible',
        color: 'blue-100',
        icon: 'mdi-check'
      },
      failed: {
        detail: 'Error de descarga',
        color: 'red-100',
        icon: 'mdi-alert-circle'
      },
      expired: {
        detail: 'Descarga caducada',
        color: 'red-100',
        icon: 'mdi-close'
      }
    },
    typeTask: {
      documents_issued: 'Documentos emitidos',
      documents_received: 'Documentos recibidos',
      documents_distributed: 'Documentos distribuidos - reporte',
      documents_received_from_sii: 'Documentos pendientes por recibir'
    },
    breadcrumbs: {
      main: 'Centro de descarga',
      children: []
    }
  }),
  computed: {
    ...mapState({
      count: state => state.downloadCenter.async_tasks_infoCount,
      tasksList: state => state.downloadCenter.async_tasks_infoList
    }),
    heightTable () {
      if (this.$vuetify.breakpoint.height >= 800 && this.tasksList.length < 17) return 'auto'
      return 'calc(100vh - 275px)'
    }
  },
  created () {
    this.getList()
  },
  methods: {
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    getList () {
      this.loading = true
      this.$store.dispatch('downloadCenter/LIST', {
        resource: 'async_tasks_info',
        query: this.$route.query
      })
      .then(() => {
          // this.$dialog.message.info('La exportación se realizó con éxito')
        })
        .catch((response) => {
          // this.$dialog.message.error('Ha ocurrido un error en la exportación')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>